import "./App.css";
import { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
export default function App() {
  const [subnetMaskDecimal, setSubnetMaskDecimal] = useState([
    255, 255, 255, 0,
  ]);

  const [senderIPDecimal, setSenderIPDecimal] = useState([192, 168, 1, 1]);

  const [receiverIPDecimal, setReceiverIPDecimal] = useState([192, 168, 1, 2]);

  const [mouseHoverBinaryPosition, setMouseHoverBinaryPosition] = useState([
    null,
    null,
  ]);

  const [subnetMaskBinary, setSubnetMaskBinary] = useState([]);

  const [senderIPBinary, setSenderIPBinary] = useState([]);

  const [receiverIPBinary, setReceiverIPBinary] = useState([]);

  useEffect(() => {
    let arraySubnet = subnetMaskDecimal.map((decimal) => {
      let s = decimal.toString(2);
      while (s.length < 8) s = "0" + s;
      return { number: s };
    });
    setSubnetMaskBinary(arraySubnet);
    let arraySender = senderIPDecimal.map((decimal) => {
      let s = decimal.toString(2);
      while (s.length < 8) s = "0" + s;
      return { number: s, nonMatched: [] };
    });
    let arrayReceiver = receiverIPDecimal.map((decimal) => {
      let s = decimal.toString(2);
      while (s.length < 8) s = "0" + s;
      return { number: s, nonMatched: [] };
    });

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 8; j++) {
        if (arraySubnet[i].number[j] === "0") {
          break;
        }
        if (arraySender[i].number[j] !== arrayReceiver[i].number[j]) {
          arraySender[i] = {
            number: arraySender[i].number,
            nonMatched: [...arraySender[i].nonMatched, j],
          };
          arrayReceiver[i] = {
            number: arrayReceiver[i].number,
            nonMatched: [...arrayReceiver[i].nonMatched, j],
          };
        }
      }
    }

    setSenderIPBinary(arraySender);
    setReceiverIPBinary(arrayReceiver);
  }, [senderIPDecimal, receiverIPDecimal, subnetMaskDecimal]);

  return (
    <Container
      sx={{
        p: 4,
      }}
    >
      <Typography
        sx={{
          position: "fixed",
        }}
        variant="h6"
      >
        Made by: Joon Shakya
        <br />
        V1.0.0
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="h3"
      >
        Subnet Mask
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="h3"
      >
        {subnetMaskDecimal.join(".")}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="h4"
      >
        {subnetMaskBinary.map((octet, i) => {
          return (
            <Box
              key={i}
              sx={{
                display: "inline-block",
              }}
            >
              {new Array(octet.number.length).fill(" ").map((_, j) => {
                return (
                  <Box
                    key={j}
                    sx={{
                      display: "inline-block",
                      cursor: "pointer",
                      color: mouseHoverBinaryPosition[0]
                        ? i < mouseHoverBinaryPosition[0]
                          ? "#eb7734"
                          : i === mouseHoverBinaryPosition[0] &&
                            j < mouseHoverBinaryPosition[1] + 1
                          ? "#eb7734"
                          : "#0dcf06"
                        : octet.number.charAt(j) === "1"
                        ? "#eb7734"
                        : "#0dcf06",
                    }}
                    onMouseEnter={() => {
                      setMouseHoverBinaryPosition([i, j]);
                    }}
                    onMouseLeave={() => {
                      setMouseHoverBinaryPosition([null, null]);
                    }}
                    onClick={() => {
                      console.log({ i, j });
                      let array = [];
                      for (let k = 0; k < 4; k++) {
                        let number;
                        if (i > k) {
                          number = 255;
                        } else if (i === k) {
                          let s = "";
                          for (let l = 0; l <= j; l++) {
                            s += "1";
                          }
                          while (s.length < 8) s = s + "0";
                          number = parseInt(s, 2);
                        } else {
                          number = 0;
                        }
                        array.push(number);
                      }
                      setSubnetMaskDecimal(array);
                    }}
                  >
                    {octet.number.charAt(j)}
                  </Box>
                );
              })}
              {i !== 3 ? "." : ""}
            </Box>
          );
        })}
      </Typography>
      <Box
        sx={{
          display: "flex",
          pt: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            textAlign: "center",
            p: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
            }}
          >
            Sender Device
          </Typography>
          <ComputerIcon
            sx={{
              fontSize: "12rem",
            }}
          />

          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="h3"
          >
            {senderIPDecimal.join(".")}
          </Typography>
          {senderIPBinary.map((octet, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "inline-block",
                }}
              >
                {new Array(octet.number.length).fill(" ").map((_, j) => {
                  return (
                    <Box
                      key={j}
                      sx={{
                        fontSize: "1.8rem",
                        display: "inline-block",
                        cursor: "pointer",
                        color: senderIPBinary[i].nonMatched.includes(j)
                          ? "#f0f500"
                          : subnetMaskBinary[i].number.charAt(j) === "1"
                          ? "#eb7734"
                          : "#0dcf06",
                      }}
                      onClick={() => {
                        console.log({ i, j });
                        let binary = senderIPBinary[i].number;
                        binary =
                          binary.substring(0, j) +
                          (binary.charAt(j) === "1" ? "0" : "1") +
                          binary.substring(j + 1);
                        let array = [...senderIPDecimal];
                        array[i] = parseInt(binary, 2);
                        setSenderIPDecimal(array);
                      }}
                    >
                      {octet.number.charAt(j)}
                    </Box>
                  );
                })}
                {i !== 3 ? "." : ""}
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            flex: 1,
            textAlign: "center",
            p: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
            }}
          >
            Receiver Device
          </Typography>
          <ComputerIcon
            sx={{
              fontSize: "12rem",
            }}
          />

          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="h3"
          >
            {receiverIPDecimal.join(".")}
          </Typography>
          {receiverIPBinary.map((octet, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "inline-block",
                }}
              >
                {new Array(octet.number.length).fill(" ").map((_, j) => {
                  return (
                    <Box
                      key={j}
                      sx={{
                        fontSize: "1.8rem",
                        display: "inline-block",
                        cursor: "pointer",
                        color: receiverIPBinary[i].nonMatched.includes(j)
                          ? "#f0f500"
                          : subnetMaskBinary[i].number.charAt(j) === "1"
                          ? "#eb7734"
                          : "#0dcf06",
                      }}
                      onClick={() => {
                        console.log({ i, j });
                        let binary = receiverIPBinary[i].number;
                        binary =
                          binary.substring(0, j) +
                          (binary.charAt(j) === "1" ? "0" : "1") +
                          binary.substring(j + 1);
                        let array = [...receiverIPDecimal];
                        array[i] = parseInt(binary, 2);
                        setReceiverIPDecimal(array);
                      }}
                    >
                      {octet.number.charAt(j)}
                    </Box>
                  );
                })}
                {i !== 3 ? "." : ""}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
        }}
      >
        {senderIPBinary
          .map((octet) => octet.nonMatched.length)
          .filter((a) => a > 0).length > 0
          ? "Different"
          : "Same"}{" "}
        Network
      </Typography>
    </Container>
  );
}
