import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0066FF",
      text: "#2B3674",
    },
    secondary: {
      main: "#19857b",
      text: "#707EAE",
    },
    subtitle: {
      text: "#A3AED0",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ["Consolas", "monospace"].join(","),
  },
  text: {
    main: "#ff0000",
    primary: "#ff0000",
    secondary: "#707EAE",
  },
});

export default theme;
